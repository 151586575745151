import type {
  DirectionKey,
  Middleware,
  MiddlewareFocusHook,
} from '@canalplus/one-navigation';
import { KEY_LEFT, KEY_RIGHT } from '@canalplus/one-navigation';

export type HandlerHideTab = (visible: boolean) => void;

/**
 * One-navigation middleware that handle tab visiblity when navigate horizontally
 * @param handler function that will be called with parameter visible
 */
export const hideTabHorizontalNavigation: Middleware<HandlerHideTab> =
  (handlerHideTab) => (binder) => {
    const focus: MiddlewareFocusHook = (direction: DirectionKey) => {
      // these are used to avoid navigation on Left for first element and Right for last element
      const binders = binder.getElements();
      const currentBinder = binder.layer.current;

      if (
        (direction === KEY_LEFT && binders[0] !== currentBinder) ||
        (direction === KEY_RIGHT &&
          binders[binders.length - 1] !== currentBinder)
      ) {
        handlerHideTab(false);
      }

      return undefined;
    };

    return {
      focusEnter: focus,
      focusWithin: focus,
    };
  };
