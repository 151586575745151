import { DIMENSIONS } from '@canalplus/dive-utils';
import { type ResizeMode } from '@canalplus/mycanal-commons';
import { ImageType } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import type { ApiV2ButtonOnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import Linker from '../../../components/Linker/Linker';
import styles from './Stub.css';

type StubProps = {
  text?: string;
  title?: string;
  URLImage?: string;
  onClick?: ApiV2ButtonOnClick;
  resizeMode?: ResizeMode;
  buttonLabel?: string;
};

function Stub({
  text,
  title,
  URLImage,
  onClick,
  resizeMode,
  buttonLabel,
}: StubProps): JSX.Element {
  return (
    <div className={classNames(styles.Stub, 'Stub')}>
      {URLImage && (
        <ImageType
          className={styles.Stub__image}
          URLImage={{ default: URLImage }}
          dimensions={DIMENSIONS.STUB_IMAGE}
          isTvDevice={$_BUILD_RENDERMODE_CSR}
          resizeMode={resizeMode}
        />
      )}
      {title && <h2 className={styles.Stub__title}>{title}</h2>}
      {text && <p className={styles.Stub__text}>{text}</p>}
      {onClick && (
        <Binder>
          <Linker
            className={classNames(
              `button-primary`,
              styles.Stub__button,
              styles.Stub__button__tvDevice,
            )}
            data={{ mainOnClick: onClick }}
            id="stub"
          >
            {buttonLabel || onClick.displayName}
          </Linker>
        </Binder>
      )}
    </div>
  );
}

export default Stub;
